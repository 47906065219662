<script setup>
  import { ref, h, defineEmits, onMounted, watch } from 'vue';
  import "vue-select/dist/vue-select.css";
  import VueSelect from "vue-select";
  import { useI18n } from 'vue-i18n';
  import { useLanguageStore } from '../../stores/LanguageStore';

  const props = defineProps({
    items: Array,
    placeholder: String,
    name: String,
    id: String,
    initialKey: Object,
    required: Boolean,
    dynamic: Boolean,
    autoStart: {
      type: Boolean,
      default: false
    },
    options: Object
  });

  const { t } = useI18n();
  const emits = defineEmits(['selectedItem', 'clearSelect']);
  const searchFieldText = ref(`🔍 ${props.placeholder}${props.required ? ` (${t('searchable_select.required')})` : ''}`);
  const nested = typeof props.items[0]['children'] === 'object' ? ref(true) : ref(false);
  const defaultMessage = 'searchable_select.no_matching_results';
  const userMessage = ref(t(defaultMessage));
  const DEBOUNCE_TIME = 2000;
  const INITIAL_KEY_TIMEOUT = 250;
  let selectedItem = ref(null);
  let selectedItemId = ref(null);
  let items = typeof props.items === 'object' ? ref(props.items) : ref([]);
  let debouncedWord = '';
  let timer = null;

  // For dynamic components, add your store here
  let store = null;
  if (props.items === 'LanguageStore') {
    store = useLanguageStore();
  }

  function mappedNodes() {
    const mappedNodes = [];
    props.items.forEach(node => {
      mappedNodes.push({...node, type: 'parent'})
      node.children.forEach(child => {
        if (!mappedNodes.some(item => item.key === child.key)) {
          mappedNodes.push({...child, type: 'child'});
        }
      });
    });
    return mappedNodes;
  }

  if (props.autoStart) {
    window.initializeSearchableSelect();
  }

  // Some component options
  VueSelect.props.components.default = () => ({
    OpenIndicator: {
      render: () => h('i', { class: 'mdc-select__dropdown-icon' }),
    }
  });

  const bubbleInputChange = () => {
    const input = document.getElementById(props.id);
    if (input === null) return;
    input.setAttribute('value', typeof selectedItemId === 'object' ? selectedItemId.value : selectedItemId);
    input.dispatchEvent(new Event('change', { bubbles: true }));
  };

  const selectedHandle = (val) => {
    selectedItem.value = val;
    selectedItemId.value = val.key;
    if (selectedItemId) bubbleInputChange();
    emits('selectedItem', {id: selectedItemId, name: selectedItem.value.label });
  };

  watch(() => selectedItem.value, (value) => {
    if(!value) {
      const input = document.getElementById(props.id);
      input.setAttribute('value', '');
      emits('clearSelect')
    }
  });

  const debounce = (text) => {
    debouncedWord = text;
    if (timer === null) {
      timer = setTimeout(() => {
        timer = null;
        // Change how the store's result is managed acording to your needs
        if (props.items === 'LanguageStore') {
          languageSearch(debouncedWord);
        }
      }, DEBOUNCE_TIME);
    }
  }

  // Language search
  const languageSearch = async (search) => {
    if (search.length < 2 || search.length == 0) return;
    userMessage.value = t(defaultMessage);
    await store.fetchLanguage(search);
    if (!store.loading) {
      const dynamicData = JSON.parse(JSON.stringify(store.data)).map((item) => {
        const currentLang = props.options.current_language;
        return {
          key: item.alpha2,
          label: `${currentLang !== null ? item[`name_${currentLang}`] : item.name_en} ${item.name_original !== null ? `(${item.name_original})` : ''}`
        };
      });
      items.value = dynamicData;
    }
  }

  const onSearch = async (search) => {
    if (props.dynamic) {
      userMessage.value = t('searchable_select.searching');
      debounce(search);
    }
  }

  onMounted(() => {
    setTimeout(function () {
      if (props.initialKey === null || props.initialKey === undefined) return;
      if (props.initialKey.key !== '' && props.initialKey.key !== undefined) {
        selectedItem.value = props.initialKey;
        selectedItemId.value = props.initialKey.key;
        bubbleInputChange();
      }
    }, INITIAL_KEY_TIMEOUT);
  });
</script>

<template>
  <VueSelect
    :options="nested ? mappedNodes() : items"
    v-model="selectedItem"
    :searchable="true"
    :placeholder="searchFieldText"
    :name="name"
    :filterable="props.dynamic ? false : true"
    @option:selected="selectedHandle"
    @search="onSearch"
  >
    <template #option="{ label, type }" v-if="nested">
      <span v-if="type == 'parent'"></span>
      <span v-if="type == 'child'"></span>
      <span :class="type == 'parent' ? 'node' : 'child'">{{ label }}</span>
    </template>

    <template #no-options>{{ userMessage }}</template>

    <template #search="{attributes, events}">
      <input :id="`input_${props.id}`" v-bind="attributes" v-on="events" class="vs__search" />
    </template>

  </VueSelect>

  <input
    :required="props.required"
    v-model="selectedItemId"
    :name="name"
    :id="id"
    class="required_input"
    ref="required_input"
  />
</template>

<style>
  .required_input {
    opacity: 0;
    width: 0;
    height: 0;
    float: left;
  }
  .node {
    font-weight: bold;
    text-transform: uppercase;
  }
  .child {
    padding-left: 6px;
  }
  .searchable-select {
    width: 100%;
  }
  .vs__dropdown-toggle {
    width: 100%;
    border: none;
    background-color: whitesmoke;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: inline-flex;
    position: relative;
    box-sizing: border-box;
    height: 56px;
    overflow: hidden;
    will-change: opacity, transform, color;
  }
  .vs__dropdown-menu {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: rgba(0, 0, 0, 0.65);
  }
  .vs__dropdown-toggle:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
  .vs__clear {
    fill: var(--vs-controls-color);
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    margin-right: 36px;
  }
  .vs__selected {
    padding-left: 16px;
    padding-right: 52px;
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.009375em;
    text-decoration: inherit;
    text-transform: inherit;
  }
  .vs__search:focus {
    margin-left: 25px;
  }
  .vs__search::placeholder {
    padding-left: 6px;
    padding-right: 52px;
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.009375em;
    text-decoration: inherit;
    text-transform: inherit;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.65);
  }
</style>

<i18n src="../../locales/en.json" global></i18n>
<i18n src="../../locales/fr.json" global></i18n>
<i18n src="../../locales/de.json" global></i18n>
<i18n src="../../locales/it.json" global></i18n>
<i18n src="../../locales/es.json" global></i18n>
<i18n src="../../locales/pt.json" global></i18n>
